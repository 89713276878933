<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-9">
          <card v-if="task.running">
            <h4>{{task.name}}</h4>
            <progress-bar size="big" v-bind:val="task.percent" v-bind:text="task.percent" v-bind:title="task.percent"></progress-bar>
          </card>
          <card>
            <template slot="header">
              <h4 class="card-title">Project: {{ project_name }}</h4>
              <p class="card-category">Users and SSH keys</p>
              <button class="btn btn-success" v-on:click="addUserForm">Add user</button>
            </template>
            <h4 v-if="this.new_user_form===true" class="card-title">Add User </h4>
            <form v-if="this.new_user_form===true" v-on:submit.prevent="submitForm">
              <div v-if="this.errors.length">
                <b>Please fix errors:</b>
              <ul>
                <li v-for="error in errors">{{ error }}</li>
              </ul>
              </div>
              <div class="form-group">
                <label for="user">User name</label>
                <input type="text" class="form-control" id="user" placeholder="Description" v-model="form.name">
                <label for="key">SSH Key</label>
                <textarea type="text" rows="8" cols="60" class="form-control" id="key" placeholder="Shh key" v-model="form.key">
                </textarea>
                <label for="desc">Description</label>
                <input type="text" class="form-control" id="desc" placeholder="Description" v-model="form.description">
              </div>
              <Loader v-if="this.task.running"/>
              <div v-else class="form-group">
                <button class="btn btn-primary">Add user</button>
                <button type="reset" v-on:click="formCancel" class="btn btn-danger">Cancel</button>
              </div>
            </form>
          </card>
          <card v-if="this.new_user_form===false" class="strpied-tabled-with-hover"
                body-classes="table-full-width table-responsive"
          >
            <template slot="header">
              <h4 class="card-title">Project users</h4>
            </template>
            <table class="table">
              <thead>
              <slot name="columns">
                <tr>
                  <th>User</th>
                  <th>_</th>
                </tr>
              </slot>
              </thead>
              <tbody>
              <tr v-for="user in this.user_info.UsersList" :key="user">

                <td>{{ user.UserName }}</td>
                <td>
                  <button class="btn btn-info" v-on:click="userKeySettings(user.UserName)"><i class="fa fa-cog"></i></button>
                  <button class="btn btn-danger" v-on:click="delUser(user.UserName)"><i class="fa fa-trash"></i></button>
                </td>

              </tr>
              </tbody>
            </table>
          </card>
        </div>
        <TaskProgress v-bind:task="this.task"/>
      </div>
    </div>
  </div>
</template>
<script>

  import TaskProgress from "../components/Custom/TaskProgress";
  import Loader from '@/components/Custom/Loader.vue'
  import ProgressBar from 'vue-simple-progress'


  export default {
    components: {
      TaskProgress,
      Loader,
      ProgressBar
    },
    props: ['project_name'],
    mounted() {
      this.getUsers();
      this.timer2 = setInterval(this.getUsers, 10000);

    },
    beforeDestroy() {
      clearInterval(this.timer2);
    },
    data(){
      return{
        user_info: {},
        host: window.location.protocol + "//" + window.location.host,
        new_user_form: false,
        task: {
          name: '',
          id: 0,
          start_task_time: 0,
          data: '',
          percent: 0,
          running: false
        },
        form: {
          name: '',
          key: '',
          description: ''
        },
        errors: []
      }
    },
    methods: {

      addUserForm(){
        this.new_user_form=true;
      },
      formCancel(){
        this.errors = []
        this.new_user_form=false;
      },
      userKeySettings(user_name) {
        this.$router.push("/admin/project/"+ this.project_name +"/key/"+user_name);
      },
      getUsers(){
        fetch(this.host + '/api/projects/'+ this.project_name +'/user_list/')
          .then(response => response.json())
          .then(json => {this.user_info = json; console.log(this.user_info)})
      },
      delUser(username) {
        this.task.name = 'Delete user ' + username
        this.task.percent = 0;
        fetch(this.host + '/api/projects/' + this.project_name + '/user/' + username +
          '/delete/')
          .then(response => response.json())
          .then(json => {
            this.task.id = json.task_id;
            this.task.start_task_time = Math.round(+new Date()/1000);
            this.task.running = true;
          })
      },
      submitForm(){
        this.errors = [];
        if (!this.form.name) {
          this.errors.push('Set up user name');
        }
        if (!this.form.key) {
          this.errors.push('Set up key');
        }
        if (!this.form.description) {
          this.errors.push('Set up key name');
        }
        if(this.errors.length){
          return
        }
        //this.task.name = 'Create project ' + this.form.pname
        this.task.name = 'Create user ' + this.form.name
        this.task.percent = 0;
        try {
          const response = fetch(this.host + '/api/projects/'+ this.project_name + '/add_user/', {
            method: 'POST', // или 'PUT'
            body: JSON.stringify(this.form), // данные могут быть 'строкой' или {объектом}!
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(response => response.json())
            .then(json => {
              this.task.id = json.task_id;
              this.task.start_task_time = Math.round(+new Date()/1000);
              this.task.running = true;
              this.new_user_form=false;
            })
        } catch (error) {
          console.error('Ошибка:', error);
        }
      }

    }
  }
</script>
