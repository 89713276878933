<template>
  <sidebar-link v-bind:to="'/admin/project/'+ this.current_project + '/' + this.role">
    <i class="nc-icon nc-chart-pie-35"></i>
    <p>{{ this.role }}</p>
  </sidebar-link>
</template>

<script>
  export default {
    props: ['current_project', 'role'],

  }
</script>
