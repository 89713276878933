<template>
  <div class="container">
    <div class="item" v-bind:class="msg.class" v-for="msg of this.messages" :key="msg"> {{msg.text}} </div>
  </div>
</template>
<script>
  export default {
    props: ['messages']
  }
</script>
<style scoped>
.container {
  border: 2px solid #ccc;
  padding: 10px;
  width: 20em;
  background-color: #333333;
}

.item {
  width: -moz-fit-content;
  width: fit-content;

  padding: 5px;
  margin-bottom: 1em;
}
.task {
  color: #EEE8AA;
}

.info {
  color: #8ca0ff;
}
.ok {
  color: #90EE90;
}
.err{
  color:#FA8072;
}
</style>
