<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card   class="strpied-tabled-with-hover">
            <template slot="header">
              <h4 class="card-title">Server: {{ server_name }}</h4>
            </template>

            <!--p>{{ this.server_detail }}</p-->

            <table class="table">
              <tbody>
                <tr><td>Server name</td><td> {{this.server_detail.Name}}</td></tr>
                <tr><td>Cluster name</td><td> {{this.server_detail.ClusterName}}</td></tr>
                <tr><td>Server role</td><td> {{this.server_detail.Role}}</td></tr>
                <tr><td>Status</td><td> {{this.server_detail.Status}}</td></tr>
                <tr><td>SSH</td><td> {{this.server_detail.ssh_path_ip}}</td></tr>
                <tr><td>SSH local</td><td> {{this.server_detail.ssh_path_local}}</td></tr>
                <tr><td>OS</td><td> {{this.server_detail["image.os"]}} {{this.server_detail["image.version"]}}</td></tr>
                <tr><td>Limit CPU</td><td> {{this.server_detail.limit_cpu}}</td></tr>
                <tr><td>Limit CPU priority</td><td> {{this.server_detail.limit_cpu_priority}}</td></tr>
                <tr><td>Limit CPU allowance</td><td> {{this.server_detail.limit_cpu_allowance}}</td></tr>
                <tr><td>Limit processes</td><td> {{this.server_detail.limit_processes}}</td></tr>
                <tr><td>Limit Memory</td><td> {{this.server_detail.limits_memory}}</td></tr>
                <tr><td>Limit Network priority</td><td> {{this.server_detail.limit_network_priority}}</td></tr>
              </tbody>
            </table>


            <!--Progress

              v-bind:current_task="this.task.name"
              v-bind:current_task_id="this.task.id"
              v-bind:description="this.task.data"
            /-->
            <!--form v-on:submit.prevent="submitForm">
              <div class="form-group">
                <label for="sname">Add Server</label>
                <input type="text" class="form-control" id="sname" placeholder="Server name" v-model="form.sname">
              </div-->
              <!--div class="form-group">
                <input  type="checkbox" id="projectSettings" v-on:change="show=!show">
                <label for="projectSettings">Project settings</label>
              </div>

              <div class="form-group" v-if="show">
                <h6>Storage</h6>
                <label for="storsize">Storage Gb</label>
                <input type="text" class="form-control" id="storsize" placeholder="Storage Gb" v-model="form.stsize">
              </div>

              <div class="form-group" v-if="show">
                <h6>Bridge 1</h6>
                <label for="b1ip">IP address</label>
                <input type="text" class="form-control" id="b1ip" placeholder="IP address" v-model="form.b1ip">
                <label for="b1nat">NAT</label>
                <select name="b1nat" class="form-control" id="b1nat" v-model="form.b1nat">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
                <label for="b1fw">Firewall</label>
                <select name="b1fw" class="form-control" id="b1fw" v-model="form.b1fw">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
                <label for="b1dhcp">DHCP</label>
                <select name="b1dhcp" class="form-control" id="b1dhcp" v-model="form.b1dhcp">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
                <label for="b1r">Routing</label>
                <select name="b1r" class="form-control" id="b1r" v-model="form.b1r">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
              </div>
              <div class="form-group" v-if="show">
                <h6>Bridge 2</h6>
                <label for="b2ip">IP address</label>
                <input type="text" class="form-control" id="b2ip" placeholder="IP address" v-model="form.b2ip">
                <label for="b2nat">NAT</label>
                <select name="b2nat" class="form-control" id="b2nat" v-model="form.b2nat">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
                <label for="b2fw">Firewall</label>
                <select name="b2fw" class="form-control" id="b2fw" v-model="form.b2fw">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
                <label for="b2dhcp">DHCP</label>
                <select name="b2dhcp" class="form-control" id="b2dhcp" v-model="form.b2dhcp">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
                <label for="b2r">Routing</label>
                <select name="b2r" class="form-control" id="b2r" v-model="form.b2r">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
              </div-->


              <!--div class="form-group">
                <button class="btn btn-primary">Submit</button>
              </div>
            </form-->

          </card>


        </div>

      </div>
    </div>
  </div>
</template>
<script>
  import Card from 'src/components/Cards/Card.vue'
  //import Progress from '@/components/Custom/TaskProgress.vue'

  export default {
    props: ['project_name', 'server_name'],
    components: {
      Card,
      //Progress
    },
    data(){
      return{
        server_detail: {},
        host: window.location.protocol + "//" + window.location.host,

      }
    },
    methods:{

      serverDetails(){
        try {
          const response = fetch(
          this.host + '/api/projects/' + this.project_name + '/server_detail/' + this.server_name + '/',
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(response => response.json())
            .then(json => {
              this.server_detail = json
              console.log(json)
            })
          } catch (error) {
            console.error('Ошибка:', error);
        }
      }
    },
    mounted() {
      this.serverDetails();
    }
  }

</script>
<style>

</style>
