<template>
  <transition name="fade" mode="out-in">
    <router-view
      v-on:selectProj="selectProj"
      v-on:hideProjMenu="hideProjMenu"
    >
    </router-view>
  </transition>
</template>
<script>
  export default {
    methods:{
      selectProj(item){
        this.$emit('selectProj', item);
      },
      hideProjMenu(){
        this.$emit('hideProjMenu');
      }
    }
  }
</script>
<style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .1s
  }

  .fade-enter,
  .fade-leave-to
    /* .fade-leave-active in <2.1.8 */

  {
    opacity: 0
  }
</style>
