<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-9">
          <card v-if="task.running">
            <h4>{{task.name}}</h4>
            <progress-bar size="big" v-bind:val="task.percent" v-bind:text="task.percent" v-bind:title="task.percent"></progress-bar>
          </card>
          <card class="strpied-tabled-with-hover"
                body-classes="table-full-width table-responsive"
          >
            <form v-on:submit.prevent="submitForm">
              <div v-if="this.errors.length">
                <b>Please fix errors:</b>
                <ul>
                  <li v-for="error in errors">{{ error }}</li>
                </ul>
              </div>
              <div class="form-group">
                <label for="key">SSH Key</label>
                <textarea type="text" rows="8" cols="60" class="form-control" id="key" placeholder="Shh key" v-model="form.key">
                </textarea>
                <label for="desc">Description</label>
                <input type="text" class="form-control" id="desc" placeholder="Description" v-model="form.description">
              </div>


              <Loader v-if="this.task.running"/>
              <div v-else class="form-group">
                <button class="btn btn-primary">Add key</button>
              </div>
            </form>
          </card>
          <card class="strpied-tabled-with-hover"
                body-classes="table-full-width table-responsive"
          >
            <template slot="header">
              <h4 class="card-title">Users keys</h4>
            </template>
            <table class="table">
              <thead>
              <slot name="columns">
                <tr>
                  <th>Key</th>
                  <th>_</th>
                </tr>
              </slot>
              </thead>
              <tbody>
              <tr v-for="key in this.user_keys.KeysList" :key="key">

                <td>{{ key.NameKey }}</td>
                <td> </td>
                <td>
                  <button class="btn btn-danger" v-on:click="delKey(key.NameKey)"><i class="fa fa-trash"></i></button>
                </td>

              </tr>
              </tbody>
            </table>
          </card>
        </div>
        <TaskProgress v-bind:task="this.task"/>
      </div>
    </div>
  </div>
</template>
<script>
import TaskProgress from "../components/Custom/TaskProgress"
import Loader from '@/components/Custom/Loader.vue'
import ProgressBar from 'vue-simple-progress'

export default {
  components: {
    TaskProgress,
    Loader,
    ProgressBar
  },
  props: ['project_name', 'user'],
  data(){
    return {
      form: {
        key: '',
        description: ''
      },
      host: window.location.protocol + "//" + window.location.host,
      user_keys: {},
      errors: [],
      task: {
        name: '',
        id: 0,
        start_task_time: 0,
        data: '',
        percent: 0,
        running: false
      }
    }
  },
  mounted() {
    this.get_keys();
    this.timer = setInterval(this.get_keys, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {

    get_keys(){
      fetch( this.host + '/api/projects/'+ this.project_name +'/user_keys/' + this.user + '/')
        .then(response => response.json())
        .then(json => {this.user_keys = json})
    },
    delKey(key) {
      this.task.name = 'DELETE KEY ' + key
      this.task.percent = 0;
      fetch( this.host + '/api/projects/' + this.project_name + '/user_keys/' + this.user +
        '/delete/' + key + '/')
        .then(response => response.json())
        .then(json => {
          this.task.id = json.task_id;
          this.task.start_task_time = Math.round(+new Date()/1000);
          this.task.running = true;
      })
    },
    submitForm(){
      this.errors = [];
      if (!this.form.key) {
        this.errors.push('Set up key');
      }
      if (!this.form.description) {
        this.errors.push('Set up key name');
      }
      if(this.errors.length){
        return
      }
      this.task.name = 'ADD KEY ' + this.form.description
      this.task.percent = 0;
      try {
        const response = fetch( this.host + '/api/projects/'+ this.project_name +
          '/user_keys/' + this.user + '/add_key/', {
            method: 'POST', // или 'PUT'
            body: JSON.stringify(this.form), // данные могут быть 'строкой' или {объектом}!
            headers: {
              'Content-Type': 'application/json'
            }
        }).then(response => response.json())
          .then(json => {
            this.task.id = json.task_id;
            this.task.start_task_time = Math.round(+new Date()/1000);
            this.task.running = true;
          })
      } catch (error) {
        console.error('Ошибка:', error);
      }
    }
  }
}
</script>
