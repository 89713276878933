<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-9">
          <card v-if="task.running">
            <h4>{{task.name}}</h4>
            <progress-bar size="big" v-bind:val="task.percent" v-bind:text="task.percent" v-bind:title="task.percent"></progress-bar>
          </card>


          <card>


            <button class="btn btn-success" v-on:click="showNewProjForm">CREATE NEW PROJECT</button>
            <form v-if="this.new_proj_form===true" v-on:submit.prevent="submitForm">
              <div v-if="this.errors.length">
                <b>Please fix errors:</b>
                <ul>
                  <li v-for="error in errors">{{ error }}</li>
                </ul>
              </div>
              <div class="form-group">
                <label for="pname">Project name</label>
                <input type="text" class="form-control" id="pname" placeholder="Project name" v-model="form.pname">
                <label for="pdesc">Project description</label>
                <input type="text" class="form-control" id="pdesc" placeholder="Project name" v-model="form.description">
              </div>
              <div class="form-group">
                <h6>Storage</h6>
                <label for="storsize">Storage Gb</label>
                <input type="text" class="form-control" id="storsize" placeholder="Storage Gb" v-model="form.stsize">
              </div>
              <!--div class="form-group">
                <input  type="checkbox" id="projectSettings" v-on:change="show=!show">
                <label for="projectSettings">Project settings</label>
              </div-->
              <div class="form-group">
                <label for="settings">Project settings</label>
                <label class="switch" id="settings">
                  <input type="checkbox" v-on:change="show=!show">
                  <span class="slider round"></span>
                </label>
              </div>

              <div class="form-group" v-if="show">
                <h6>Bridge 1</h6>
                <label for="b1ip">IP address</label>
                <input type="text" class="form-control" id="b1ip" placeholder="IP address" v-model="form.b1ip">
                <label for="b1nat">NAT</label>
                <select name="b1nat" class="form-control" id="b1nat" v-model="form.b1nat">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
                <label for="b1fw">Firewall</label>
                <select name="b1fw" class="form-control" id="b1fw" v-model="form.b1fw">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
                <label for="b1dhcp">DHCP</label>
                <select name="b1dhcp" class="form-control" id="b1dhcp" v-model="form.b1dhcp">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
                <label for="b1r">Routing</label>
                <select name="b1r" class="form-control" id="b1r" v-model="form.b1r">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
              </div>
              <div class="form-group" v-if="show">
                <h6>Bridge 2</h6>
                <label for="b2ip">IP address</label>
                <input type="text" class="form-control" id="b2ip" placeholder="IP address" v-model="form.b2ip">
                <label for="b2nat">NAT</label>
                <select name="b2nat" class="form-control" id="b2nat" v-model="form.b2nat">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
                <label for="b2fw">Firewall</label>
                <select name="b2fw" class="form-control" id="b2fw" v-model="form.b2fw">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
                <label for="b2dhcp">DHCP</label>
                <select name="b2dhcp" class="form-control" id="b2dhcp" v-model="form.b2dhcp">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
                <label for="b2r">Routing</label>
                <select name="b2r" class="form-control" id="b2r" v-model="form.b2r">
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
              </div>

              <Loader v-if="this.task.running"/>
              <div v-else class="form-group">
                <button class="btn btn-primary">Create</button>
                <button type="reset" v-on:click="formCancel" class="btn btn-danger">Cancel</button>
              </div>
            </form>
          </card>
          <card v-if="this.new_proj_form===false"  class="strpied-tabled-with-hover"
                body-classes="table-full-width table-responsive"
          >
            <template slot="header">
              <h4 class="card-title">Project list</h4>
            </template>
            <table class="table">
              <thead>
              <slot name="columns">
                <tr>
                  <th>Project name</th>
                  <th>Description</th>
                  <th>Status</th>
                  <th>_</th>
                </tr>
              </slot>
              </thead>
              <tbody>
              <tr v-for="item in this.projects" :key="item">
                <td><a href="#" v-on:click="projectDetails(item.name)" >{{ item.name }}</a></td>
                <td> {{ item.description }} </td>
                <td>

                  <p v-if="item.status === 1" class="status open"><i class="current-status-indicator green"></i>Running</p>
                  <p v-else-if="item.status === 0" class="status dead"><i class="current-status-indicator red"></i>Dead</p>
                  <p v-else class="status in-progress"><i class="current-status-indicator yellow"></i>In Progress</p>

                </td>
                <td>
                  <button v-if="item.status === 0" class="btn btn-success" v-on:click="projectStart(item.name)"><i class="fa fa-play"></i></button>
                  <button v-if="item.status === 1" class="btn btn-warning" v-on:click="projectStop(item.name)"><i class="fa fa-stop"></i></button>
                  <button class="btn btn-info" v-on:click="projectDetails(item.name)"><i class="fa fa-cog"></i></button>
                  <button class="btn btn-danger" v-on:click="delProject(item.name)"><i class="fa fa-trash"></i></button>
                </td>

              </tr>
              </tbody>
            </table>
          </card>
        </div>
        <TaskProgress v-bind:task="this.task"/>
      </div>
    </div>
  </div>
</template>
<script>

import Loader from '@/components/Custom/Loader.vue'
import ProgressBar from 'vue-simple-progress'
import TaskProgress from "../components/Custom/TaskProgress";

export default {
  components: {
    Loader, ProgressBar, TaskProgress
  },
  methods: {
    submitForm(){
      this.errors = [];
      if (!this.form.pname) {
        this.errors.push('Set up project name');
      }
      if(this.errors.length){
        return
      }
      this.task.name = 'Create project ' + this.form.pname;
      this.task.percent = 0;
      //this.messages.push({text: this.task.name, class: 'task'});
      try {
        const response = fetch('https://test1.devel3.retloko.dev/api/projects/create/', {
          method: 'POST', // или 'PUT'
          body: JSON.stringify(this.form), // данные могут быть 'строкой' или {объектом}!
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(response => response.json())
          .then(json => {
            this.task.id = json.task_id;
            this.task.start_task_time = Math.round(+new Date()/1000);
            this.task.running = true;
            this.new_proj_form=false;
          })
      } catch (error) {
        console.error('Ошибка:', error);

      }
    },
    delProject(item){
      console.log(item)
      this.task.name = 'Delete project ' + item;
      this.task.percent = 0;
      //this.messages.push({text: this.task.name, class: 'task'});
      const uri = 'https://test1.devel3.retloko.dev/api/projects/delete/'+item+'/'
      console.log(uri)
      fetch(uri)
        .then(response => response.json())
        .then(json => {
          this.task.id = json.task_id;
          this.task.start_task_time = Math.round(+new Date()/1000);
          this.task.running = true;

        })
    },
    projectStart(item) {
      console.log('TEST!')
      console.log(item)
      const name = 'Start project' + item
      const uri = 'https://test1.devel3.retloko.dev/api/projects/start/'+item+'/'
      this.task.name = name;
      this.task.percent = 0;
      //this.messages.push({text: this.task.name, class: 'task'});
      console.log(uri)
      fetch(uri)
        .then(response => response.json())
        .then(json => {
          this.task.id = json.task_id;
          this.task.start_task_time = Math.round(+new Date()/1000);
          this.task.running = true;
          console.log(this.task.start_task_time);
        })
    },
    projectStop(item) {
      console.log(item)
      const name = 'Stop project' + item
      const uri = 'https://test1.devel3.retloko.dev/api/projects/stop/'+item+'/'
      this.task.name = name;
      this.task.percent = 0;
      //this.messages.push({text: this.task.name, class: 'task'});
      console.log(uri)
      fetch(uri)
        .then(response => response.json())
        .then(json => {
          this.task.id = json.task_id;
          this.task.start_task_time = Math.round(+new Date()/1000);
          this.task.running = true;
          console.log(this.task.start_task_time);
        })
    },
    projList() {
      fetch('https://test1.devel3.retloko.dev/api/projects/all_status/')
        .then(response => response.json())
        .then(json => {
          this.projects = json.projects
        })
    },
    projectDetails(item){
      console.log('ROUTE TO');
      console.log("/admin/project/"+item);
      this.$emit('selectProj', item);
      this.$router.push("/admin/project/"+item+"/detail");
    },
    showNewProjForm(){
      this.new_proj_form=true;
    },
    formCancel(){
      this.errors = []
      this.new_proj_form=false;
    }
  },
  data () {
    return {
      projects: [],
      errors: [],

      task: {
        name: '',
        id: 0,
        start_task_time: 0,
        data: '',
        running: false,
        percent: 0
      },
      form: {
        pname: '',
        description: '',
        stsize: 20,
        b1ip: 'auto',
        b1nat: 'true',
        b1fw: 'false',
        b1dhcp: 'true',
        b1r: 'true',
        b2ip: 'auto',
        b2nat: 'false',
        b2fw: 'false',
        b2dhcp: 'true',
        b2r: 'true'
      },
      show: false,
      new_proj_form: false,
      //messages: []
    }
  },

  mounted() {
    console.log('TEST MOUNTED');
    this.projList();
    this.$emit('hideProjMenu');
    this.timer2 = setInterval(this.projList, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer2);
  }
}
</script>
<style scoped>
.btn-danger{
  background-color: LightPink; /* Blue background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 16px; /* Some padding */
  font-size: 16px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
  margin: 5px;
}

/* Darker background on mouse-over */
.btn-danger:hover {
  background-color: FireBrick;
}
.btn-info{
  background-color: DodgerBlue; /* Blue background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 16px; /* Some padding */
  font-size: 16px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
  margin: 5px;
}

/* Darker background on mouse-over */
.btn-info:hover {
  background-color: RoyalBlue;
}
.btn-warning{
  background-color: yellow; /* Blue background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 16px; /* Some padding */
  font-size: 16px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
  margin: 5px;
}

/* Darker background on mouse-over */
.btn-warning:hover {
  background-color: gold;
}
.btn-success{
  background-color: #08ec69; /* Blue background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 16px; /* Some padding */
  font-size: 16px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
  margin: 5px;
}

/* Darker background on mouse-over */
.btn-success:hover {
  background-color: green;
}
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}


.current-status-indicator {
  width: 12px;
  height: 12px;
  margin: 0 0 0 5px;
  display: inline-block;
  border-radius: 6px
}

.current-status-indicator.small {
  width: 8px;
  height: 8px;
  margin: 0 0 0 5px;
  display: inline-block;
  border-radius: 4px
}

.current-status-indicator.green {
  background: #27ae60
}

.current-status-indicator.yellow {
  background: #ffa837
}

.current-status-indicator.red {
  background: #c44031
}

.current-status-indicator.blue {
  background: #00aaf0
}

</style>
