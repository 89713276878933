<template>
  <div class="wrapper">
    <side-bar>
      <mobile-menu slot="content"></mobile-menu>
      <sidebar-link v-on:click="hideProjMenu" to="/admin/overview">
        <i class="nc-icon nc-chart-pie-35"></i>
        <p>Projects</p>
      </sidebar-link>
      <DynamicMenu v-if="this.current_project"
                   v-bind:current_project="this.current_project"
                   v-bind:role="this.roles.detail"
      />
      <DynamicMenu v-if="this.current_project"
                   v-bind:current_project="this.current_project"
                   v-bind:role="this.roles.key"
      />
      <DynamicMenu v-if="this.current_project"
                   v-bind:current_project="this.current_project"
                   v-bind:role="this.roles.action"
      />
    </side-bar>
    <div class="main-panel">
      <!--top-navbar></top-navbar-->

      <dashboard-content @click="toggleSidebar"
                         v-on:selectProj="selectProj"
                         v-on:hideProjMenu="hideProjMenu">
      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">

</style>
<script>
  //import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import MobileMenu from './MobileMenu.vue'
  import DynamicMenu from '@/components/Custom/DynamicMenu.vue'
  export default {

    components: {
      //TopNavbar,
      ContentFooter,
      DashboardContent,
      MobileMenu,
      DynamicMenu
    },
    methods: {
      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      },
      selectProj(item){
        console.log('SELECTED' + item);
        this.current_project = item
      },
      hideProjMenu(){
        console.log('HIDDING');
        this.current_project = null;
      }
    },
    data () {
      return {
        current_project: null,
        roles: {
          'detail': 'detail',
          'key': 'key',
          'action': 'action'
        },
      }
    }
  }

</script>
