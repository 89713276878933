<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-9">
          <card v-if="task.running">
            <h4>{{task.name}}</h4>
            <progress-bar size="big" v-bind:val="task.percent" v-bind:text="task.percent" v-bind:title="task.percent"></progress-bar>
          </card>
          <card>
            <template slot="header">
              <h4 class="card-title">Project: {{ project_name }}</h4>
              <p class="card-category">Make action under project</p>
            </template>

            <form >
              <div class="form-group">
                <label for="user">Select user </label>
                <select class="form-control" id="user" v-model="selectedUser">
                  <option v-for="user in this.users" :key="user">{{ user }}</option>
                </select>
                <p>Script will run under {{this.selectedUser}} user</p>
              </div>
            </form>
            <table class="table">
              <thead>
              <slot name="columns">
                <tr>
                  <th>Action</th>
                  <th>Run</th>

                </tr>
              </slot>
              </thead>
              <tbody>
              <tr v-for="action in this.actions" :key="action">

                <td>{{ action.title }}</td>

                <td>
                  <button class="btn btn-success" v-on:click="actionStart(action.action)"><i class="fa fa-play"></i></button>
                </td>

              </tr>
              </tbody>
            </table>

          </card>

        </div>
        <TaskProgress v-bind:task="this.task"/>
      </div>
    </div>
  </div>
</template>
<script>

  import TaskProgress from "../components/Custom/TaskProgress";
  import Loader from '@/components/Custom/Loader.vue'
  import ProgressBar from 'vue-simple-progress'

  export default {
    components: {
      TaskProgress,
      Loader,
      ProgressBar
    },
    props: ['project_name'],
    mounted() {
      this.prepareData();
    },

    data(){
      return{
        host: window.location.protocol + "//" + window.location.host,
        actions: [],
        users: [],
        selectedUser: 'webrtc',
        task: {
          name: '',
          id: 0,
          start_task_time: 0,
          data: '',
          percent: 0,
          running: false
        },
      }
    },
    methods: {
      prepareData(){
        fetch(this.host + '/api/projects/action_data/' + this.project_name + '/')
          .then(response => response.json())
          .then(json => {
            this.actions = json.actions; this.users = json.users;
            console.log(this.actions);
            console.log(this.users);
          })
      },
      actionStart(action_name){
        console.log(action_name)
        const name = 'Start action ' + action_name
        const uri = this.host + '/api/projects/make_action/'
        this.task.name = name
        this.task.percent = 0;
        console.log(uri)
        try {
          const response = fetch(uri, {
            method: 'POST', // или 'PUT'
            body: JSON.stringify({'project': this.project_name, 'action': action_name, 'user': this.selectedUser}),
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(response => response.json())
            .then(json => {
              this.task.id = json.task_id;
              this.task.start_task_time = Math.round(+new Date()/1000);
              this.task.running = true;
            })
        } catch (error) {
          console.error('Ошибка:', error);

        }
      }
    }
  }
</script>
