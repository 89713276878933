import DashboardLayout from '../layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../pages/NotFoundPage.vue'

// Admin pages
import Overview from 'src/pages/Overview.vue'
import UserProfile from 'src/pages/UserProfile.vue'
import TableList from 'src/pages/TableList.vue'
import Typography from 'src/pages/Typography.vue'
import Project from 'src/pages/Project.vue'
import ProjectKeys from 'src/pages/ProjectKeys.vue'
import ProjectAction from 'src/pages/ProjectAction.vue'
import ProjectKeysUser from 'src/pages/ProjectKeysUser.vue'
import Test from 'src/pages/Test.vue'
import Server from 'src/pages/Server.vue'
import Icons from 'src/pages/Icons.vue'
//import Maps from 'src/pages/Maps.vue'
import Notifications from 'src/pages/Notifications.vue'
import Upgrade from 'src/pages/Upgrade.vue'

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/admin/overview'
  },
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/overview',
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'test',
        name: 'test',
        component: Test
      },
      {
        path: 'project/:project_name/detail',
        name: 'Project',
        component: Project,
        props: true,
      },
      {
        path: 'project/:project_name/key',
        name: 'ProjectKeys',
        component: ProjectKeys,
        props: true,
      },
      {
        path: 'project/:project_name/action',
        name: 'ProjectAction',
        component: ProjectAction,
        props: true,
      },
      {
        path: 'project/:project_name/key/:user',
        name: 'ProjectKeysUser',
        component: ProjectKeysUser,
        props: true,
      },
      {
        path: 'project/:project_name/server/:server_name',
        name: 'Server',
        component: Server,
        props: true
      }
    ]
  },
  { path: '*', component: NotFound }
]

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes
