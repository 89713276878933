<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8">
          <form @submit.prevent="runScript">
            <input type="text" v-model="script_name">
            <button type="submit">Run</button>
          </form>
        </div>
        <div class="col-md-4">
          <Progress
            v-bind:current_task="this.current_task"
            v-bind:current_status="this.current_status"
            v-bind:current_task_id="this.task_id"
            v-bind:description="this.description"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Progress from '@/components/Custom/TaskProgress.vue'

  export default {
    components: {
      Progress
    },
    methods: {
      runScript() {
        console.log(this.script_name)
        this.current_task = 'Running script ' + this.script_name
        this.current_status = 'Request to server'
        const uri = 'https://test1.devel3.retloko.dev/api/run_script/' + this.script_name + '/'
        this.script_name = ''
        console.log(uri)
        fetch(uri)
          .then(response => response.json())
          .then(json => {
            this.task_id = json.task_id;
            this.current_status = 'Start'
          })
      },
      fetchStatus(){
        if(this.current_status === 'Start'){
          const uri = 'https://test1.devel3.retloko.dev/api/status/'
          console.log(uri)
          fetch(uri)
            .then(response => response.json())
            .then(json => {
              this.current_status = json.Result;
              this.description = json
            })
        }
      }
    },
      data () {
        return {
          script_name: '',
          task_id: 0,
          current_status: '',
          description: ''

        }
      },
    mounted() {
      this.timer = setInterval(this.fetchStatus, 50000)
    }

  }

</script>
<style>

</style>
