<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-9">

          <card>
            <template slot="header">
              <h4 class="card-title">Project: {{ project_name }}</h4>
            </template>
            <Loader v-if="this.task.running"/>
            <button v-else class="btn btn-success" v-on:click="addServerForm">Add server</button>
            <div v-if="task.running">
              <h4>{{task.name}}</h4>
              <progress-bar size="big" v-bind:val="task.percent" v-bind:text="task.percent" v-bind:title="task.percent"></progress-bar>
            </div>
            <h4 v-if="this.new_server_form===true" class="card-title">Add Server </h4>
            <form v-if="this.new_server_form===true" >
              <div class="form-group">
                <label for="role">Select server role </label>
                <select class="form-control" id="role" v-model="selected" v-on:change="selectType()">
                  <option v-for="s_type in this.serverTypes" :key="s_type">{{ s_type.Role }}</option>
                </select>
              </div>
            </form>
            <form v-if="this.new_server_form===true" v-on:submit.prevent="submitForm">
              <div v-if="this.errors.length">
                <b>Please fix errors:</b>
                <ul>
                  <li v-for="error in errors">{{ error }}</li>
                </ul>
              </div>
              <div class="form-group">
                <h3>{{this.form.role}}</h3>
                <label for="sname">Server Name</label>
                <input type="text" class="form-control" id="sname" placeholder="Server name" v-model="form.sname">
                <label for="cpu">CPU</label>
                <input type="number" class="form-control" id="cpu" placeholder="CPU" v-model="form.limits_cpu">
                <label for="mem">Memory in GB</label>
                <input type="number" class="form-control" id="mem" placeholder="Memory" v-model="form.limits_memory">
              </div>
              <div class="form-group">
                <label for="os">OS</label>
                <select class="form-control" id="os" v-model="form.TYPE_OS">
                  <option selected="selected">ubuntu:16.04</option>
                  <option>ubuntu:18.04</option>
                  <option>ubuntu:22.04</option>
                </select>
              </div>


              <Loader v-if="this.task.running"/>
              <div v-else class="form-group">
                <button class="btn btn-primary">Add server</button>
                <button type="reset" v-on:click="formCancel" class="btn btn-danger">Cancel</button>
              </div>
            </form>

          </card>
          <card v-if="this.new_server_form===false" class="strpied-tabled-with-hover"
                body-classes="table-full-width table-responsive"
          >
            <template slot="header">
              <h4 class="card-title">Server list</h4>
              <table class="table">
                <thead>
                <slot name="columns">
                  <tr>
                    <th>Server name</th>
                    <th>Role</th>
                    <th>SSH</th>
                    <th>Status</th>
                    <th>_</th>
                  </tr>
                </slot>
                </thead>
                <tbody>
                <tr v-for="server in this.server_list" :key="server">

                  <td><a href="#" v-on:click="serverDetails(server.name)" >{{ server.name }}</a></td>
                  <td> {{ server.role }} </td>
                  <td> {{ server.ssh }} </td>
                  <td>
                    <p v-if="server.status === 1" class="status open"><i class="current-status-indicator green"></i>Running</p>
                    <p v-else-if="server.status === 0" class="status dead"><i class="current-status-indicator red"></i>Dead</p>
                    <p v-else-if="server.status === -1" class="status unknown"><i class="current-status-indicator grey"></i>Checking</p>
                    <p v-else class="status in-progress"><i class="current-status-indicator yellow"></i>In Progress</p>
                  </td>
                  <td>
                    <button v-if="server.status === 0" class="btn btn-success" v-on:click="serverStart(server.name)"><i class="fa fa-play"></i></button>
                    <button v-if="server.status === 1" class="btn btn-warning" v-on:click="serverStop(server.name)"><i class="fa fa-stop"></i></button>
                    <button class="btn btn-info" v-on:click="serverDetails(server.name)"><i class="fa fa-cog"></i></button>
                    <button class="btn btn-danger" v-on:click="delServer(server.name)"><i class="fa fa-trash"></i></button>
                  </td>

                </tr>
                </tbody>
              </table>
            </template>
          </card>

        </div>
        <TaskProgress v-bind:task="this.task"/>
      </div>
    </div>
  </div>
</template>
<script>
  import Card from 'src/components/Cards/Card.vue'
  import TaskProgress from "../components/Custom/TaskProgress";
  import Loader from '@/components/Custom/Loader.vue'
  import axios from "axios"
  import ProgressBar from 'vue-simple-progress'


  export default {
    props: ['project_name'],
    components: {
      Card,
      TaskProgress,
      Loader,
      ProgressBar
    },
    data(){
      return{
        project_detail: {},
        server_list: [],
        form:{
          sname: '',
          project_name: this.project_name,
          TYPE_OS : "ubuntu:16.04",
          limits_cpu: 2,
          limits_cpu_allowance : 50,//%
          limits_cpu_priority :10,
          limits_memory :4,
          limits_processes : 100,
          limits_network_priority :10,
          role: 'stream'
        },
        host: window.location.protocol + "//" + window.location.host,
        task: {
          name: '',
          id: 0,
          start_task_time: 0,
          data: '',
          percent: 0,
          running: false
        },
        errors: [],
        new_server_form: false,
        serverTypes: [],
        selected: ''
      }
    },
    methods:{
      selectType(){
        console.log(this.selected);
        let s_type = {}
        this.serverTypes.forEach(s => {
          if(s.Role === this.selected){
            this.form.limits_cpu = s.limits_cpu;
            this.form.role = s.Role;
            this.form.limits_memory = s.limits_memory;
          }
        })

      },
      addServerForm(){
        this.new_server_form=true;
      },
      formCancel(){
        this.errors = []
        this.new_server_form=false;
      },
      submitForm(){
        this.errors = [];
        if (!this.form.sname) {
          this.errors.push('Set up server name');
        }
        if(this.errors.length){
          return
        }

        try {
          this.task.name = 'ADD SERVER'
          this.task.percent = 0;
          const response = fetch(this.host + '/api/projects/add_server/', {
            method: 'POST', // или 'PUT'
            body: JSON.stringify(this.form), // данные могут быть 'строкой' или {объектом}!
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(response => response.json())
            .then(json => {
              this.task.id = json.task_id;
              this.task.start_task_time = Math.round(+new Date()/1000);
              this.task.running = true;
              this.new_server_form = false;
            })
        } catch (error) {
          console.error('Ошибка:', error);
        }
      },

      /*serverStatus(){
        this.server_list.forEach(s =>{
          const get_uri =  'https://test1.devel3.retloko.dev/api/projects/' + this.project_name + '/server_status/' + s.name + '/'
          const check_uri =  'https://test1.devel3.retloko.dev/api/projects/' + this.project_name + '/server_detail/' + s.name + '/'
          axios.get(get_uri);
          fetch(check_uri).then(response => response.json()).then(
            json => {
              if(json.Status === 'Running'){
                s.status = 1;
              }
              else{
                s.status = 0;
              }
            }
          )
        })
      },*/
      serverDetails(server_name){
        console.log('ROUTE TO');
        console.log("/admin/server/"+server_name);
        this.$router.push("/admin/project/"+ this.project_name +"/server/"+server_name);
      },
      delServer(server_name){
        try {
          this.task.name = 'REMOVE SERVER' + server_name
          this.task.percent = 0;
          const response = fetch(
            this.host + '/api/projects/' + this.project_name + '/server_delete/' + server_name + '/',
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
            }).then(response => response.json())
            .then(json => {
              this.task.id = json.task_id;
              this.task.start_task_time = Math.round(+new Date()/1000);
              this.task.running = true;
            })
        } catch (error) {
          console.error('Ошибка:', error);
        }
      },
      getServerList(){
        fetch(this.host + '/api/projects/server_list/' + this.project_name + '/')
          .then(response => response.json())
          .then(json => {this.server_list=json.server_list})
      },
      serverStop(server_name){
        try {
          this.task.name = 'STOPING SERVER ' + server_name
          this.task.percent = 0;
          const response = fetch(
            this.host + '/api/projects/' + this.project_name + '/server_stop/' + server_name + '/',
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
            }).then(response => response.json())
            .then(json => {
              this.task.id = json.task_id;
              this.task.start_task_time = Math.round(+new Date()/1000);
              this.task.running = true;
            })
        } catch (error) {
          console.error('Ошибка:', error);
        }
      },
      serverStart(server_name){
        try {
          this.task.name = ' STARTING SERVER ' + server_name
          this.task.percent = 0;
          const response = fetch(
            this.host + '/api/projects/' + this.project_name + '/server_start/' + server_name + '/',
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
            }).then(response => response.json())
            .then(json => {
              this.task.id = json.task_id;
              this.task.start_task_time = Math.round(+new Date()/1000);
              this.task.running = true;
            })
        } catch (error) {
          console.error('Ошибка:', error);
        }
      }
    },
    mounted() {
      fetch(this.host + '/api/projects/detail/' + this.project_name + '/')
        .then(response => response.json())
        .then(json => {this.project_detail=json});
      this.getServerList();
      fetch(this.host + '/api/server_types/')
        .then(response => response.json())
        .then(json => {this.serverTypes=json.server_types; console.log(this.serverTypes)});
      this.timer2 = setInterval(this.getServerList, 10000);


    },
    beforeDestroy() {
      clearInterval(this.timer2);

    }
  }

</script>
<style scoped>
.btn-danger{
  background-color: LightPink; /* Blue background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 16px; /* Some padding */
  font-size: 16px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
  margin: 5px;
}

/* Darker background on mouse-over */
.btn-danger:hover {
  background-color: FireBrick;
}
.btn-info{
  background-color: DodgerBlue; /* Blue background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 16px; /* Some padding */
  font-size: 16px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
  margin: 5px;
}

/* Darker background on mouse-over */
.btn-info:hover {
  background-color: RoyalBlue;
}
.btn-warning{
  background-color: yellow; /* Blue background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 16px; /* Some padding */
  font-size: 16px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
  margin: 5px;
}

/* Darker background on mouse-over */
.btn-warning:hover {
  background-color: gold;
}
.btn-success{
  background-color: #08ec69; /* Blue background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 16px; /* Some padding */
  font-size: 16px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
  margin: 5px;
}
/* Darker background on mouse-over */
.btn-success:hover {
  background-color: green;
}

.current-status-indicator {
  width: 12px;
  height: 12px;
  margin: 0 0 0 5px;
  display: inline-block;
  border-radius: 6px
}

.current-status-indicator.small {
  width: 8px;
  height: 8px;
  margin: 0 0 0 5px;
  display: inline-block;
  border-radius: 4px
}

.current-status-indicator.green {
  background: #27ae60
}

.current-status-indicator.yellow {
  background: #ffa837
}

.current-status-indicator.red {
  background: #c44031
}

.current-status-indicator.grey {
  background: #B0B0B0
}

</style>
