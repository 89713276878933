<template>
  <div class="col-3">
    <Terminal v-bind:messages="this.messages"/>
  </div>
</template>

<script>
import Terminal from '@/components/Custom/Terminal.vue'

export default {
  components: {
    Terminal
  },
  props: ['task'],
  data(){
    return{
      messages: [],
      host: window.location.protocol + "//" + window.location.host,
      start: false
    }
  },
  methods: {
    getDifference(a, b) {
      var i = 0;
      var j = 0;
      var result = "";

      while (j < b.length) {
        if (a[i] != b[j] || i == a.length)
          result += b[j];
        else
          i++;
        j++;
      }
      return result;
    },
    fetchStatus() {

      if (this.task.running === true) {
        if(this.start === false){
          this.start = true;
          this.messages.push({text: 'START TASK: ' + this.task.name, class: 'info'});
        }

        let now;
        const uri = this.host + '/api/projects/status/' + this.task.id + '/'
        console.log(uri)
        now = Math.round(+new Date() / 1000)
        console.log(now);
        console.log(this.task.start_task_time);
        if (now - this.task.start_task_time > 180) {
          console.log('TIMEOUT');
          this.task.running = false;
          //TODO TIMEOUT ERROR TEXT
          this.messages.push({text: 'TASK FINISHED BY TIMEOUT' + this.task.name, class: 'err'});
          this.new_proj_form = false;
        }
        fetch(uri)
          .then(response => response.json())
          .then(json => {
            let diff = this.getDifference(this.task.data, json['message']);
            if (diff.length > 0) {
              let logData = diff.split('#');
              console.log(logData);
              logData.forEach(element => {
                if (element.length > 0) {
                  element = element.replace('"', '');
                  element = element.replace('"', '');
                  element = element.split(':')[1];
                  if (element) {
                    if (element.startsWith('Error')) {
                      this.messages.push({text: element, class: 'err'})
                    } else if (element.startsWith('Ok') || element.startsWith('OK')) {
                      this.messages.push({text: element, class: 'ok'})
                    } else {
                      this.messages.push({text: element, class: 'info'})
                    }
                  }

                }
              });
            }
            this.task.data = json['message'];
            this.task.percent = json['percent'];
            console.log(json['percent']);


            if (json['percent'] === 100) {
              console.log('OK FINISH')
              this.messages.push({text: 'TASK COMPLETED: ' + this.task.name, class: 'info'});
              this.task.running = false;
              this.new_proj_form = false;
            }
          })
      }
      else{
        this.start = false;
      }
    },
  },//methods
  mounted() {

    this.timer1 = setInterval(this.fetchStatus, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timer1);
  }

}
</script>
